section > h5 {
  padding-top: 2rem;
}

.about_container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50% 45%;
      grid-template-columns: 50% 45%;
  gap: 5%;
  place-items: center;
}

.about_container .about_content p {
  text-align: center;
  margin-bottom: 2rem;
}

.about_container .about_content p span {
  color: #bc142e;
  font-weight: bold;
}

.about_container .about_content .about_cards {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about_container .about_content .about_cards .about_card {
  background: var(--color-bg-variant);
  border: 0.2% solid transparent;
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.about_container .about_content .about_cards .about_card:hover {
  background: transparent;
  -webkit-box-shadow: 0 0 7px var(--color-primary-variant);
          box-shadow: 0 0 7px var(--color-primary-variant);
  cursor: default;
}

.about_container .about_content .about_cards .about_card:hover small {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #bc142e;
}

.about_container .about_content .about_cards .about_card .about_icon {
  color: var(--color-primary);
  font-size: 1.4rem;
}

.about_container .about_content .about_cards .about_card h5 {
  font-size: 0.95rem;
}

.about_container .about_content .about_cards .about_card small {
  font-size: 0.7rem;
  color: var(--color-light);
  text-shadow: 0 0 0.2px var(--color-primary);
}

.about_container .image-collage {
  width: 100%;
  height: 100%;
  display: -ms-grid;
  display: grid;
  place-items: center;
}

.about_container .image-collage .image-collection {
  position: relative;
  display: block;
  width: 90%;
  height: 100%;
  margin: auto;
}

.about_container .image-collage .image-collection .collage-img {
  position: absolute;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.about_container .image-collage .image-collection .collage-img:nth-child(1) {
  width: 75%;
  height: 77%;
  top: 7%;
}

.about_container .image-collage .image-collection .collage-img:nth-child(2) {
  width: 63%;
  height: 62%;
  top: 35%;
  left: 20%;
}

.about_container .image-collage .image-collection .collage-img:nth-child(3) {
  width: 56%;
  height: 68%;
  top: 14%;
  right: 0;
}

.about_container .image-collage .image-collection .collage-img:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  z-index: 2;
  -webkit-transition: all 0.8s ease-in;
  transition: all 0.8s ease-in;
}

.about_container .image-collage .image-collection:hover .collage-img:not(:hover) {
  -webkit-filter: blur(8px);
          filter: blur(8px);
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
}

/* -----------Media Queries for Medium Devices----------- */
@media screen and (max-width: 1180px) {
  .about_container {
    gap: 4%;
    -ms-grid-columns: 50% 46%;
        grid-template-columns: 50% 46%;
  }
  .about_container .about_content .about_cards {
    gap: 0.5rem;
  }
  .about_container .image-collage .image-collection {
    width: 100%;
  }
}

/* -----------Media Queries for Medium  Portrait Devices----------- */
@media screen and (max-width: 820px) {
  #about h2 {
    margin-bottom: 1rem;
  }
  #about .about_container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    gap: 0;
  }
  #about .about_container .about_content {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  #about .about_container .about_content p {
    margin: 0.5rem 0 1.5rem;
    text-align: center;
    font-size: 20px;
  }
  #about .about_container .image-collage {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 0;
    margin: 0;
  }
  #about .about_container .image-collage .image-collection {
    height: 400px;
    width: 80%;
  }
}

/* -----------Media Queries for Small Devices----------- */
@media screen and (max-width: 500px) {
  #about .about_container .about_content p {
    font-size: 15px;
  }
  #about .about_container .about_content .about_cards {
    display: none;
  }
  #about .about_container .image-collage .image-collection {
    width: 100%;
    height: 340px;
  }
}

/* -----------Media Queries for Smaller Devices----------- */
@media screen and (max-width: 380px) {
  #about .about_container .about_content p {
    font-size: 12px;
  }
  #about .about_container .about_content .about_cards {
    display: none;
  }
  #about .about_container .image-collage .image-collection {
    width: 100%;
    height: 300px;
  }
}

#experience{
    .experience_container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    
        .card_one, .card_two{
            flex-basis: 45%;
            flex-grow: 100%;
            
            .flip-container {
                perspective: 1000;
                display: inline-block;
                width: 100%;
                height: 300px;
                
                &:hover .card {
                    transform: rotateY(180deg);
                }
            }
        }

        .card_one{
            img{
                bottom: 4rem;
            }
        }
    }
    .tap_here{
        display: none;
        .icon{
            font-size: 20px;
            margin-top: 6px;
        }
    }
}

.card, .front, .back {
    width: 100%;
    height: 100%;
}
.card {
    transition: 0.5s;
    transform-style: preserve-3d;
    position: relative;
}
.front, .back {
    backface-visibility: hidden;
    position: absolute;
    border-radius: 2rem;
}
.front {
    text-align: center;
    z-index: 2;
    transform: rotateY(0deg);
    background: no-repeat;
    background-image: linear-gradient(var(--color-bg-variant), rgba(92, 226, 250, 0.7), var(--color-bg-variant));
    
    h3{
        padding-top: 1rem;
        font-size: 20px;
    }
    
    .experience_content{
        .experience_details{
            display: flex;
            justify-content: center;
            gap: 0.5rem;

                .experience_details_icon{
                    margin-top: 6px;
                }
            }
            
        img{
            width: 130px;
            position: absolute;
            bottom: 3.5rem;
            left: 50%;
            transform: translate(-50%);            
        }        
    }
}

.back {
    z-index: 1; /* back side, placed above front */
    transform: rotateY(180deg);
    background: rgb(197, 242, 250) url(../../img/experience/Nova_Scotia.webp) no-repeat fixed center;
    background-size: cover;
    color: white;
    h3{
        padding: 1rem;
        text-align: center;
        color: red;
        font-size: 1.2rem;
    }

    .back_lists{
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 60%;
        margin: 5px 10px;

        ul{
            li{
                font-size: 15px;
                border-radius: 5px;
                width: fit-content;
                padding: 2px;
                margin: 5px;
                background-color: rgba($color: #000000, $alpha: 0.5);

                &:nth-child(even){
                    background-color: rgba($color: #fff, $alpha: 0.4);
                    color: red;
                }
            }

            .list_icon{
                padding-top: 3px;
            }
        }
    }
}

/* -----------Media Queries for Medium Devices----------- */
@media screen and (max-width: 1180px) {
    .back{
        .back_lists{
            margin: 5px;
            ul{
                li{
                    font-size: 14px;
                }
            }
        }
    }
    .tap_here{
        display: block;
    }
}

@media screen and (max-width: 820px){
    .experience_container{
        flex-direction: column;
        justify-content: center;
        
        .card_one, .card_two{
            margin-top: 3rem;
            width: 80%;
            position: relative;
            left: 50%;
            transform: translate(-50%);

            .flip-container{
                .card{
                    .back{
                        .back_lists{
                            ul{
                                li{
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
}

@media screen and (max-width: 450px){
    .experience_container{     
        .card_one, .card_two{
            margin-top: 0;   
            .flip-container{
                .card{
                    .back{
                        .back_lists{
                            ul{
                                li{
                                    font-size: 11px;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
}

@media screen and (max-width: 375px){
    .experience_container{     
        .card_one, .card_two{
            margin-top: 0;   
            .flip-container{
                .card{
                    .back{
                        .back_lists{
                            ul{
                                li{
                                    font-size: 10.2px;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
}

@media screen and (max-width: 280px){
    .experience_container{     
        .card_one, .card_two{
            margin-top: 0;   
            .flip-container{
                .card{
                    .back{
                        .back_lists{
                            ul{
                                li{
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
}
.footer-area {
  text-align: center;
  font-size: 0.9rem;
  width: 100%;
  bottom: 10px;
  padding: 3rem 0;
  background: -webkit-gradient(linear, left top, right top, from(rgba(247, 215, 151, 0.2)), to(rgba(0, 0, 0, 0.2)));
  background: linear-gradient(90deg, rgba(247, 215, 151, 0.2), rgba(0, 0, 0, 0.2));
  border-top: 2px solid rgba(205, 133, 63, 0.5);
}

.footer-area .footer_container .logo {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  display: inline-block;
}

.footer-area .footer_container .section_links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 1rem;
  margin: 0 auto 2rem;
}

.footer-area .footer_container .footer_socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 0.3rem;
  margin-bottom: 2rem;
}

.footer-area .footer_container .footer_socials a {
  margin: 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.5rem;
}

.footer-area .footer_container .footer_socials a:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.footer-area .footer_container .footer_socials a:first-child {
  color: #0674e7;
}

.footer-area .footer_container .footer_socials a:nth-child(2) {
  color: #f7064c;
}

.footer-area .footer_container .footer_socials a:nth-child(3) {
  color: #1d9bf0;
}

.footer-area .footer_container .footer_socials a:nth-child(4) {
  color: #0a66c2;
}

.footer-area .footer_container .footer_socials a:last-child {
  color: #1a1d21;
}

.footer-area .footer_container .footer_copyright {
  padding: 25px 0;
  margin-bottom: 3rem;
}

.footer-area .footer_container .footer_copyright small .copy {
  font-size: 0.8rem;
}

@import url('https://cdn.jsdelivr.net/npm/boxicons@latest/css/boxicons.min.css');

.contact_container{
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;

    .contact_content{
        .contact_title{
            text-align: center;
            font-weight: bold;
            margin-bottom: 1.5rem;
        }

        .contact_info{
            display: grid;
            row-gap: 1rem;
            grid-template-columns: 300px;

            .contact_card{
                // background-color: var(--color-bg-variant);
                background-color: rgba(0,0,0,0.1);
                border: 1px solid rgba(0,0,0,0.2);
                padding: 1rem;
                border-radius: 0.75rem;
                text-align: center;

                // .contact_card_title, .contact_card_data{
                //     color: rgb(219, 40, 40);
                // }

                .contact_card_icon{
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    color: rgb(255, 72, 0);
                }

                .bxs-right-arrow-alt{
                    animation-duration: 3s;
                }

                &:nth-child(2){
                    .bxs-right-arrow-alt{
                        animation-delay: 0.2s;
                    }
                    .contact_card_icon{
                        color: green;
                    }
                }
                &:last-child{
                    .bxs-right-arrow-alt{
                        animation-delay: 0.4s;
                    }
                    .contact_card_icon{
                        color: #1b74e4;                        
                    }
                }

                .contact_button{
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    column-gap: 0.25rem;
                }
            }
        }

        .contact_form{
            width: 360px;
            position: relative;
            .contact_form_div{
                position: relative;
                margin-bottom: 2rem ;
                height: 4rem;

                .contact_form_tag{
                    position: absolute;
                    top: -0.75rem;
                    left: 1.25rem;
                    font-size: smaller;
                    padding: 0.2rem;
                    background-color: var(--color-bg);
                    z-index: 10;
                }

                .contact_form_input{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: 2px solid rgba(0,0,0,0.3);
                    background: none;
                    outline: none;
                    border-radius: 0.75rem;
                    padding: 1.5rem;
                    z-index: 1;
                }

            }
            .contact_form_area{
                height: 12rem;

                .contact_form_input{
                    resize: none;
                }
            }

            .status_msg{
                background: linear-gradient(90deg, rgba(#f7d797, 0.5), rgba(0, 0, 0, 0.2) );
                padding: 1.5rem 2.5rem;
                width: fit-content;
                border-radius: 1rem;
                position: absolute;
                left: 50%;
                bottom: 20%;
                transform: translate(-50%);
                text-align: center;
                z-index: 4;

                .msg_logo1{
                    color: green;
                }

                .msg_logo2{
                    color: red;
                }
                
                p{
                    margin-bottom: 0.6rem;
                }
                
                .toggle{
                    background: none;
                    color: rgb(167, 9, 9);
                    font-weight: 600;
                    
                    &:hover{
                        cursor: pointer;
                        color: var(--color-font);
                    }
                }
            }
        }

    }

}

// MEDIA QUERRIES
//large devices
@media screen and (max-width: 992px) {
    .contact_container{
        column-gap: 3rem;
    }
}

// Medium devices
@media screen and (max-width: 768px) {
    .contact_container{
        grid-template-columns: 1fr;
        row-gap: 3rem;

        .contact_content{
            .contact_info{
                justify-content: center;
            }
            .contact_form{
                margin: 0 auto;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .contact_info{
        grid-template-columns: 1fr;
    }
    .contact_form{
        width: 100%;
    }
}

// Smallest devices
@media screen and (max-width: 300px) {
    .contact_container{
        column-gap: 0.5rem;
        padding-bottom: 0.01rem;
        .contact_info{
            width: 270px;
            place-items: center;
            
            .contact_card{
                width: 70%;
            }
        }

        .contact_title_two{
            display: none;
        }
        .contact_form{
            display: none;
            // border: 2px solid blue;                
            // padding: 0;
            // margin: 0;
            // // place-items: center;
            // display: grid;
            // grid-template-columns: 1fr;
            // place-items: center;
            // width: auto;
            // .contact_form_div{
            //     border: 2px solid green;                
            //     width: 250px;
            // }
        }
    }
}

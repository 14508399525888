.testimonial_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 2.5rem;
}

.testimonial_card .client_avatar {
  width: 30%;
  height: 30%;
  border-radius: 50%;
  border: 2px solid peru;
  z-index: 7;
}

.testimonial_card .client_avatar img {
  border-radius: 50%;
}

.testimonial_card .client_name {
  padding: 0.5rem;
  font-weight: bold;
}

.testimonial_card .client_review {
  width: 70%;
  text-align: center;
}

.swiper-pagination-bullet {
  background-color: var(--color-primary) !important;
}

#experience .experience_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

#experience .experience_container .card_one, #experience .experience_container .card_two {
  -ms-flex-preferred-size: 45%;
      flex-basis: 45%;
  -webkit-box-flex: 100%;
      -ms-flex-positive: 100%;
          flex-grow: 100%;
}

#experience .experience_container .card_one .flip-container, #experience .experience_container .card_two .flip-container {
  -webkit-perspective: 1000;
          perspective: 1000;
  display: inline-block;
  width: 100%;
  height: 300px;
}

#experience .experience_container .card_one .flip-container:hover .card, #experience .experience_container .card_two .flip-container:hover .card {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

#experience .experience_container .card_one img {
  bottom: 4rem;
}

#experience .tap_here {
  display: none;
}

#experience .tap_here .icon {
  font-size: 20px;
  margin-top: 6px;
}

.card, .front, .back {
  width: 100%;
  height: 100%;
}

.card {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  position: relative;
}

.front, .back {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  position: absolute;
  border-radius: 2rem;
}

.front {
  text-align: center;
  z-index: 2;
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  background: no-repeat;
  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--color-bg-variant)), color-stop(rgba(92, 226, 250, 0.7)), to(var(--color-bg-variant)));
  background-image: linear-gradient(var(--color-bg-variant), rgba(92, 226, 250, 0.7), var(--color-bg-variant));
}

.front h3 {
  padding-top: 1rem;
  font-size: 20px;
}

.front .experience_content .experience_details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 0.5rem;
}

.front .experience_content .experience_details .experience_details_icon {
  margin-top: 6px;
}

.front .experience_content img {
  width: 130px;
  position: absolute;
  bottom: 3.5rem;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.back {
  z-index: 1;
  /* back side, placed above front */
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  background: #c5f2fa url(../../img/experience/Nova_Scotia.webp) no-repeat fixed center;
  background-size: cover;
  color: white;
}

.back h3 {
  padding: 1rem;
  text-align: center;
  color: red;
  font-size: 1.2rem;
}

.back .back_lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  height: 60%;
  margin: 5px 10px;
}

.back .back_lists ul li {
  font-size: 15px;
  border-radius: 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

.back .back_lists ul li:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.4);
  color: red;
}

.back .back_lists ul .list_icon {
  padding-top: 3px;
}

/* -----------Media Queries for Medium Devices----------- */
@media screen and (max-width: 1180px) {
  .back .back_lists {
    margin: 5px;
  }
  .back .back_lists ul li {
    font-size: 14px;
  }
  .tap_here {
    display: block;
  }
}

@media screen and (max-width: 820px) {
  .experience_container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .experience_container .card_one, .experience_container .card_two {
    margin-top: 3rem;
    width: 80%;
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
  }
  .experience_container .card_one .flip-container .card .back .back_lists ul li, .experience_container .card_two .flip-container .card .back .back_lists ul li {
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {
  .experience_container .card_one, .experience_container .card_two {
    margin-top: 0;
  }
  .experience_container .card_one .flip-container .card .back .back_lists ul li, .experience_container .card_two .flip-container .card .back .back_lists ul li {
    font-size: 11px;
  }
}

@media screen and (max-width: 375px) {
  .experience_container .card_one, .experience_container .card_two {
    margin-top: 0;
  }
  .experience_container .card_one .flip-container .card .back .back_lists ul li, .experience_container .card_two .flip-container .card .back .back_lists ul li {
    font-size: 10.2px;
  }
}

@media screen and (max-width: 280px) {
  .experience_container .card_one, .experience_container .card_two {
    margin-top: 0;
  }
  .experience_container .card_one .flip-container .card .back .back_lists ul li, .experience_container .card_two .flip-container .card .back .back_lists ul li {
    font-size: 10px;
  }
}

.portfolio_container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[3];
      grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.portfolio_container .portfolio_item {
  background: linear-gradient(45deg, #f7d797, rgba(0, 0, 0, 0.3));
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.portfolio_container .portfolio_item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio_container .portfolio_item .portfolio_item_image {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio_container .portfolio_item h3 {
  margin: 1.2rem 0 2rem;
  text-align: center;
}

.portfolio_container .portfolio_item .portfolio_item_cta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (max-width: 1024px) {
  .portfolio_container {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
  .portfolio_container .portfolio_item h3 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 600px) {
  .portfolio_container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    gap: 1rem;
  }
}

header {
  height: 100vh;
  padding-top: 16vh;
  overflow: hidden;
}

.header_container {
  text-align: center;
  height: 100%;
  position: relative;
}

.cta {
  margin-top: 7vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.2rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.cta a:hover {
  text-shadow: 0 0 5px white;
}

.header_socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.header_socials a:hover {
  -webkit-transform: scale(150%);
          transform: scale(150%);
}

.header_socials::before {
  content: '';
  width: 1.5px;
  height: 2rem;
  background: var(--color-primary);
}

.header_photo {
  width: 14rem;
  height: 30rem;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  margin-top: 3rem;
  overflow: hidden;
  border-radius: 0.5rem;
}

.header_photo img {
  overflow: hidden;
}

.header_photo:before {
  content: "";
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  border: 6px solid #f4f0e4;
  border-radius: 0.5rem;
  z-index: 1;
  overflow: hidden;
}

.scroll_down {
  position: absolute;
  right: -2.3rem;
  bottom: 7rem;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

/* -----------Media Queries for Medium Devices----------- */

.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    .portfolio_item{
        background: linear-gradient(45deg, #f7d797, rgba(0,0,0,0.3));
        padding: 1.2rem;
        border-radius: 2rem;
        border: 1px solid transparent;
        transition: var(--transition);

        &:hover{
            border-color: var(--color-primary-variant);
            background: transparent;
        }

        .portfolio_item_image{
            border-radius: 1.5rem;
            overflow: hidden;
        }

        h3{
            // background-color: yellowgreen;
            // width: 100%;
            margin: 1.2rem 0 2rem;
            text-align: center;
        }

        .portfolio_item_cta{
            display: flex;
            gap: 1rem;
            margin-bottom: 1rem;
            justify-content: center;
        }
    }
}

// ============ Media Querries (Medium Devices)============ 
@media screen and (max-width: 1024px){
    .portfolio_container{
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;

        .portfolio_item{
            h3{
                font-size: 1rem;
            }
        }
    }
}

// ============ Media Querries (Small Devices)============ 
@media screen and (max-width: 600px){
    .portfolio_container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}
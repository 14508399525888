#services{
    margin-bottom: 5rem;
    width: 100%;
    .services_container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;

        
        .service{
            text-align: center;
            padding: 2rem;
            transition: 0.5s;
            // border-radius: 1.5rem 0;
            
            .service_head{
                h3{
                    color: #fff;
                }
            }

            &:hover{
                background: transparent;
                border-color: var(--color-primary-variant);
                cursor: default;
            }
            
            &:first-child{
                background-color: rgb(108, 108, 229);
                &:hover{
                    background: transparent;
                    box-shadow:0 0 5px rgb(108, 108, 229);
                    cursor: default;
                    
                    h3, p{
                        color: rgb(108, 108, 229);
                    }
                }
            }

            &:nth-child(2){
                background-color: rgb(249, 215, 76);
                &:hover{
                    background: transparent;
                    // border: 1px solid rgb(249, 215, 76);
                    cursor: default;
                    box-shadow: 0 0 5px rgb(223, 180, 8);
                    
                    h3, p{
                        color: rgb(223, 180, 8);
                        // text-shadow: 0 0 10px rgb(7, 80, 7);
                    }
                }
            }

            &:nth-child(3){
                background-color: rgb(249, 123, 139);
                &:hover{
                    background: transparent;
                    box-shadow:0 0 5px rgb(223, 93, 110);
                    cursor: default;
                    
                    h3, p{
                        color: rgb(223, 92, 110);
                    }
                }
            }

            .service_list{
                li{
                    img{
                        width: 40%;
                        position: relative;
                        left: 50%;
                        transform: translate(-50%);
                        margin: 0.6rem 0;
                    }
                    p{
                        font-size: 12px;
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #services{
        .services_container{
            grid-template-columns: 1fr;
            .service{
                width: 80%;
                position: relative;
                left: 50%;
                transform: translate(-50%);
            }
        }
    }
}
// colors
// #6b74ec blue
// #ffd14b yellow
// #ffd14b pitch
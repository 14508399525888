section > h5{
    padding-top: 2rem;
}
.about_container{
    display: grid;
    grid-template-columns: 50% 45%;
    gap: 5%;
    place-items: center;

    .about_content{
        p{
            text-align: center;
            margin-bottom: 2rem;
            span{
                color: #bc142e;
                font-weight: bold;
            }
        }

        .about_cards{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.5rem;

            .about_card{
                background: var(--color-bg-variant);
                border: 0.2% solid transparent;
                border-radius: 0.5rem;
                padding: 2rem;
                text-align: center;
                transition: var(--transition);
                
                &:hover{
                    background: transparent;
                    box-shadow: 0 0 7px var(--color-primary-variant) ;
                    cursor: default;
                    
                    small{
                        transition: var(--transition);
                        color: #bc142e;
                    }
                }

                .about_icon{
                    color: var(--color-primary);
                    font-size: 1.4rem;
                }

                h5{
                    font-size: 0.95rem;
                }

                small{
                    font-size: 0.7rem;
                    color: var(--color-light);
                    text-shadow: 0 0 0.2px var(--color-primary);
                }
            }
        }
    }
    
    .image-collage {
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        
        .image-collection {
            position: relative;
            display: block;
            width: 90%;
            height: 100%;
            margin: auto;
    
          .collage-img {
            position: absolute;
            object-fit: cover;
            transition: 0.5s;
    
            &:nth-child(1) {
              width: 75%;
              height: 77%;
              top: 7%;
            }
    
            &:nth-child(2) {
              width: 63%;
              height: 62%;
              top: 35%;
              left: 20%;
            }
    
            &:nth-child(3) {
              width: 56%;
              height: 68%;
              top: 14%;
              right: 0;
            }
    
            &:hover {
              transform: translateY(-10px);
              z-index: 2;
              transition: all 0.8s ease-in;
            }
        }
        &:hover {
            .collage-img:not(:hover){
                filter: blur(8px);
                transition: all 1.5s;
             }
          }
        }
      } 
}

/* -----------Media Queries for Medium Devices----------- */
@media screen and (max-width: 1180px){
    .about_container{
        gap: 4%;
        grid-template-columns: 50% 46%;

        .about_content{
            .about_cards{
                gap: 0.5rem;
            }
        }

        .image-collage{
            .image-collection{
                width: 100%;
            }
        }
    }
}



/* -----------Media Queries for Medium  Portrait Devices----------- */
@media screen and (max-width: 820px) {

    #about{
        h2{
            margin-bottom: 1rem;
          }
        
        .about_container{
            grid-template-columns: 1fr ;
            gap: 0;
            .about_content{
                height: fit-content;
                p{
                    margin: 0.5rem 0 1.5rem;
                    text-align: center;
                    font-size: 20px;
                }
            }

            .image-collage{ 
                height: max-content;
                padding: 0;
                margin: 0;
                .image-collection{
                    height: 400px;
                    width: 80%;
                }
            }
        }
    }    
  }
  
  /* -----------Media Queries for Small Devices----------- */
  @media screen and (max-width: 500px) {
      #about{
          .about_container{
              .about_content{
                  p{
                      font-size: 15px;
                  }
                  .about_cards{
                      display: none;
                  }
              }
              .image-collage{
                  .image-collection{
                      width: 100%;
                      height: 340px;
                  }
              }
          }
      }
    
  }

  /* -----------Media Queries for Smaller Devices----------- */
  @media screen and (max-width: 380px) {
    #about{
        .about_container{
            .about_content{
                p{
                    font-size: 12px;
                }
                .about_cards{
                    display: none;
                }
            }
            .image-collage{
                .image-collection{
                    width: 100%;
                    height: 300px;
                }
            }
        }
    }
  }
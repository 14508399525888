@import url('https://fonts.googleapis.com/css2?family=Arima:wght@300;600;700&family=Cinzel&family=Kanit:wght@100;200;300;400&family=Lato&display=swap');

/* global properties */
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body{
  font-family: 'Kanit', sans-serif;
  background: var(--color-bg) url(./img/Pattern-Transparent-Background.png) center;
  color: var(--color-font);
  line-height: 1.7;
}

/* creating css variables */
:root{
  color-scheme: light;
  
  --color-bg: #f7e8c9;
  --color-bg-alt: #e0ffc2;
  --color-bg-variant: #f7d797;
  
  --color-font: #af811e;
  --color-font-two: #d5be8b;
  --color-primary: #684c10;
  --color-primary-variant: #3f2f0d;
  --color-ptag: #8b6104;
  /* --color-primary-variant: rgba(77, 181, 255, 0.4); */
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.6);
  --color-bg-hover: #f1c669;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;

  --transition: all 400ms ease;
}

html{
  scroll-behavior: smooth;
}

/* ::-webkit-scrollbar {
  width: 10px;
} */

/* 0. Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* display: none; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-font);
  border-radius: 3.5px;
  transition: 1.5s;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-ptag);
  width: 9px;
  transition: 1.5s;
}

/* ------------general styles------------ */
.container{
 width: var(--container-width-lg);
 margin: 0 auto;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
  color: var(--color-font);
}

p{
  color: var(--color-ptag);
}


h1{
  font-size: 2.5rem;
}

section{
  margin-top: 8rem;
  /* height: 100vh; */
  /* height: fit-content; */
}

section > h2, section > h5{
  text-align: center;
  color: var(--color-font);
}

section > h2{
  /* color: var(--color-primary); */
  margin-bottom: 3rem;
}

.text-light{
  color: var(--color-light);
}

/* .text-red{
  color: var(--color-red);
} */

a{
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover{
  color: var(--color-a-hover);
}

.btn{
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.6rem 1.2rem;
  /* margin: 0 0.2rem auto; */
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover{
  background: var(--color-bg-hover);
  color: var(--color-font);
  border-color: transparent;
}

.btn-primary{
  background: var(--color-primary);
  color: var(--color-bg);
}

img{
  display: block;
  width: 100%;
  object-fit: cover;
}

/* -----------Media Queries for Medium Devices----------- */
@media screen and (max-width: 1024px) {
  .container{
    width: var(--container-width-md);
  }

  section{
    margin-top: 6rem;
  }
}

/* -----------Media Queries for Small Devices----------- */
@media screen and (max-width: 600px) {
  .container{
    width: var(--container-width-sm);
  }

  section > h2{
    margin-bottom: 2rem;
  }
}

.skills_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-gap: 1rem;
    padding: 1rem;
    font-size: 1.2rem;

    .skill_box{
        padding: 0.5rem;

        .skill_title{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0.5rem;
            margin-bottom: 0.5rem;

            .img{
                flex: 0 0 100px;
                height: 100px;
                position: relative;

                .skill_icon{
                    width: 80px;
                    animation: items 4s infinite alternate;
                }

                .skill_icon2{
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%);
                }
                
                .html{
                    animation: two_items_a 5s infinite normal;
                }
                .css3{
                    animation: two_items_a 5s 2s infinite reverse;                    
                }

                .bootstrap{
                    top: 6%;
                    animation: two_items_a 5s infinite normal;
                }
                .sass{
                    top: 6%;
                    animation: two_items_a 5s 2s infinite reverse;
                }

                .django{
                    width: 140px;
                    animation: two_items_a 5s infinite normal;
                }
                
                .drf{
                    top: 22%;
                    width: 80%;
                    animation: two_items_a 5s 2s infinite reverse;
                }             

                .git{
                    animation: two_items_a 5s infinite normal;
                }
                
                .github{
                    width: 150px;
                    animation: two_items_a 5s 2s infinite reverse;
                }
            }

            h3{
                color: green;
            }
        }
    }
}

.soft_skills{
    margin-top: 3rem;
    text-align: center;

    .soft_skill{
        &:nth-child(even){
            color: green;
        }
    
        &:nth-child(odd){
            color: red;
        }
    }
}

// Keyframes

@keyframes two_items_a {
    0% {
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
}
@keyframes two_items_b {
    0%{
        opacity: 1;
    }
    33.3% {
        opacity: 0;
    }
    66.6%{
        opacity: 0;
    }
}
@keyframes items {
    0%{
        opacity: 1;
    }
    90% {
        opacity: 0.7;
    }
}

// Media Querries
@media screen and (max-width: 767px){
    .skills_container{
        padding: 0.5rem;
        .skill_box{
            padding: 0.5rem;
        }
    }
}

// @media screen and (max-width: 500px){
//     .container{
//         grid-template-columns: repeat(2, 1fr);
//         padding: 0.5rem;
//         .skill_box{
//             padding: 0.5rem;
//         }
//     }
// }

@media screen and (max-width:500px){
    .skills_container{
        grid-template-columns: repeat(2, 1fr);
        padding: 0.4rem;
        .skill_box{
            padding: 0.2rem;
            .skill_title{
                .bootstrap_label{
                    font-size: 20px;
                }
            }
        }
    }
}

@media screen and (max-width:280px){
    .skills_container{
        grid-template-columns: 1fr;
    }
}

header{
    height: 100vh;
    padding-top: 16vh;
    overflow: hidden;
}

.header_container{
    text-align: center;
    height: 100%;
    position: relative;
}

// CTA
.cta{
    margin-top: 7vh;
    display: flex;
    gap: 1.2rem;
    justify-content: center;

    a:hover{
        text-shadow: 0 0 5px white;
    }
}

// Socials
.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;

    a:hover{
        transform: scale(150%);
    }
}

.header_socials::before{
    content: '';
    width: 1.5px;
    height: 2rem;
    background: var(--color-primary);
}

// Photo
.header_photo{
    // background: var(--color-primary);
    width: 14rem;
    height: 30rem;
    position: absolute;
    // left: calc(50%-12rem);
    left: 50%;
    transform: translate(-50%);
    margin-top: 3rem;
    overflow: hidden;
    border-radius: 0.5rem;

    img{
        // position: relative;
        // top: -150px;
        // left: -230px;
        // width: 600px;
        // height: 800px;
        overflow: hidden;
    }
    &:before {
        content: "";
        position: absolute;
        top: 12px;
        left: 12px;
        right: 12px;
        bottom: 12px;
        border: 6px solid #f4f0e4;
        border-radius: 0.5rem;
        z-index: 1;
        overflow: hidden;
      }
}

// scroll
.scroll_down{
    position: absolute;
    right: -2.3rem;
    bottom: 7rem;
    transform: rotate(90deg);
}

/* -----------Media Queries for Medium Devices----------- */
// @media screen and (max-width: 1024px) {
//     header{
//         height: 68vh;
//     }
//   }
  
//   /* -----------Media Queries for Small Devices----------- */
//   @media screen and (max-width: 600px) {
//     header{
//         height: 100vh;
//     }
//     .header_socials, .scroll_down{
//         display: none;
//     }
//   }